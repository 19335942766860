<template>
  <div>
    <b-row v-show="userLogged == null">
      <b-col sm="12">
        <Select title="Usuário" field="user" :formName="formName" :required="true" url="/api/v1/adm/user/select-all"
          :showNewRegister="false" v-model="openCashier.user" />
      </b-col>
    </b-row>
    <!--  <b-row>
      <b-col sm="5">
        <InputDecimal
          title="Valor Inicial (Troco)"
          field="valorInicial"
          :formName="formName"
          :required="false"
          v-model="openCashier.openValue"
        />
      </b-col>
      <b-col sm="7">
        <Select
          title="Tipo de Pagamento"
          field="typePayment"
          :required="false"
          :markFormDirty="false"
          :propsParams="{ any: 'PDV' }"
          v-model="openCashier.typePayment"
          url="/api/v1/finance/type-payment/select-all"
          :showNewRegister="hasRule('Finance')"
          nameNewRegister="typePaymentCreateUpdate"
          titleNewRegister="Tipo de Pagamento"
          :widthNewRegister="500"
          :heightNewRegister="250"
        >
          <TypePaymentCreateUpdate
            :registerInSelect="true"
            v-model="openCashier.typePayment"
          />
        </Select>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <InputText
          title="Observação"
          field="observation"
          :formName="formName"
          :required="false"
          :maxLength="400"
          v-model="openCashier.observation"
        />
      </b-col>
    </b-row> -->
    <b-row>
      <b-col sm="12">
        <div class="text-center">
          <Button v-show="userLogged != null" _key="btnBack" type="info" title="voltar"
            classIcon="fa-solid fa-circle-arrow-left" size="small" :clicked="back" />
          <Button _key="btnCreateCashier" type="success" title="Abrir Caixa" :disabled="!isFormValid(formName)"
            size="medium" :clicked="create" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import OpenCashier from "@/components/modules/cashier/OpenCashier.js";
import TypePaymentCreateUpdate from "../finance/type-payment/TypePaymentCreateUpdate.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "OpenCashier",
  components: {
    InputDecimal,
    Select,
    TypePaymentCreateUpdate,
    InputText,
    Button,
  },
  props: {
    userLogged: Object,
    urlCreate: String,
  },
  data() {
    return {
      formName: "openCashier",
      openCashier: new OpenCashier(),
    };
  },
  mounted() {
    if (this.userLogged) {
      this.openCashier.user.id = this.userLogged.user.id;
      this.openCashier.user.content = this.userLogged.user.name;
    }
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
    ...mapGetters("user", ["hasRule"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["hideModal", "removeLoading"]),
    ...mapMutations("pdv", ["updateCashier"]),
    ...mapMutations("validation", ["resetValidation"]),
    create() {
      let params = {
        url: this.urlCreate,
        obj: this.openCashier,
        notNotifyToast: true,
      };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.updateCashier(response.content.id);
          this.hideModal();
          this.openCashier = new OpenCashier();
          this.resetValidation(this.formName);
        }
        this.removeLoading(["btnCreateCashier"]);
      });
    },
    back() {
      this.removeLoading(["btnBack"]);
      this.$router.push({
        name: "pdvRentSelectOption",
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "abrirCaixaPdv") {
          this.resetValidation(this.formName);
          this.openCashier = new AbrirCaixa();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.icon-cashier {
  font-size: 50px;
  margin-bottom: 20px;
  opacity: 0.5;
}
</style>